body#myapp { // to make sure all the !important declarations work
  
  /* Mobile */
  @media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large monitor only"]:not(.mobile),
    [class*="widescreen monitor only"]:not(.mobile),
    [class*="or lower hidden"] {
      display: none !important;
    }
    
    // expand tap targets
    // .ui.table:not(.unstackable) tr { padding: 0.5em 0; }
    table tr[data-target] > td > a:first-child { display: block; margin: -0.75em 0; padding: 0.75em 0; }
    
    // adjust size/positioning of filter query input
    .ui.icon.input { width: 100%; margin-top: 0.5em; }
  }
  
  /* Tablet / iPad Portrait */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tablet hidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large monitor only"]:not(.tablet),
    [class*="widescreen monitor only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
      display: none !important;
    }
  }
  
  /* Computer / Desktop / iPad Landscape */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computer hidden"],
    [class*="large monitor only"]:not(.computer),
    [class*="widescreen monitor only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
      display: none !important;
    }
  }
  
  /* Large Monitor */
  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large monitor"]),
    [class*="tablet only"]:not([class*="large monitor"]),
    [class*="computer only"]:not([class*="large monitor"]),
    [class*="large monitor hidden"],
    [class*="widescreen monitor only"]:not([class*="large monitor"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
      display: none !important;
    }
  }
  
  /* Widescreen Monitor */
  @media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen monitor"]),
    [class*="tablet only"]:not([class*="widescreen monitor"]),
    [class*="computer only"]:not([class*="widescreen monitor"]),
    [class*="large monitor only"]:not([class*="widescreen monitor"]),
    [class*="widescreen monitor hidden"],
    [class*="widescreen monitor or lower hidden"] {
      display: none !important;
    }
  }
  
}
