label.asdndzone * {
  box-sizing: border-box;
}

label.asdndzone {
  box-sizing: border-box;
  display: block;
  /* border: 3px solid #c8ce3e; */
  /* border-radius: 6px; */
  /* min-height: 220px; */
  /* max-width: 310px; */
  /* width: 100%; */
  /* padding: 99px 20px 20px; */
  text-align: center;
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-size: 21px; */
  /* letter-spacing: 0.6px; */

  cursor: pointer;
  border: 5px dashed #777;
  padding: 20px;
  border-radius: 25px;
}

label.asdndzone:focus-within {
  outline: 2px solid #7aacfe; /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color;
}

label.asdndzone .asdndz-highlight {
  color: #8dc63f;
}

label.asdndzone .asdndz__icon-container {
  text-align: left;
}

label.asdndzone .direct-upload {
  display: inline-block;
  position: relative;
  border-radius: 10px;
  /* height: 18px; */
  width: 275px;
  background-color: #f5f5f5;
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-size: 11px; */
  letter-spacing: 0.3px;
  color: #212121;
  margin-top: 8px;
  padding: 5px 10px 0;
}

label.asdndzone .direct-upload__complete {
  background-color: #c8ce3e;
}

label.asdndzone .direct-upload__progress {
  opacity: 0.57;
  background-color: #c8ce3e;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}

label.asdndzone .direct-upload__remove {
  display: inline-block;
  opacity: 1;
  margin: 0 .25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  &::before { content: '\f00d'; color: #212121; }

  vertical-align: top;
  margin-top: 13px;
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-size: 11px; */
  /* font-weight: bold; */
  /* text-decoration: none; */
  color: transparent;  
}

label.asdndzone .direct-upload__filename {
  position: relative;
  width: 200px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

label.asdndzone .direct-upload__filesize {
  position: relative;
  float: right;
  /* font-size: 10px; */
  letter-spacing: 0.1px;
  color: #707e87;
  padding-left: 7px;
}

label.asdndzone .direct-upload[data-direct-upload-id='error'] {
  border: 1px solid red;
}
