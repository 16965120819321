body#myapp {
  #content-container {
    padding-top: 10px;
    min-height: 90vh;
  }

  #panel .mobile.only .menu {
    border-radius: 0;
  }

  footer.inverted.segment {
    border-radius: 0;
  }

  .button-bar {
    form {
      display: inline-block;
    }

    margin-bottom: 1.53em;
    line-height: 1.7em;
  }

  #website-menu {
    color: #222;
    margin: auto 0;
    padding: 0 1em;

    .prompt {
      padding: 0.25em 1em;
      line-height: 1.1;
    }

    .results {
      width: 25em;
    }

    .result {
      white-space: nowrap;

      &:hover {
        background-color: #eee;
      }

      &.active {
        background-color: #d8ebff;
      }
    }

    .production {
      font-weight: 600;

      small {
        font-weight: normal;
      }
    }

    .redirect {
      font-style: italic;
      opacity: 0.7;
    }

    .archived {
      text-decoration: line-through;
      opacity: 0.7;
    }
  }

  .field .deleted {
    text-decoration: line-through;
    opacity: 0.7;
  }

  // .asdndzone {
  //   .asdndz__icon-container div {
  //     display: flex;
  //     width: 100%;
  //     .direct-upload__filename { margin-right: 20px; }
  //   }
  // }

  tr.obsolete {
    font-style: italic;
    opacity: 0.7;
  }

  tr.down {
    background-color: #eee;
  }

  footer {
    margin-top: 30px;
  }

  form .actions {
    background-color: #eee;
    position: sticky;
    bottom: 0;
    z-index: 2; // place above checkbox sliders
    padding: 10px 10px;
  }

  // a.click-to-copy,
  // span.click-to-copy {
  //   display: inline-block;
  // }

  .code-snippet {
    width: 100%;
    border: 1px solid gray;
    background-color: #eeeeee;
    margin-top: 8px;
    padding: 5px 10px;
    font-family: monospace;
    font-size: 0.85em;
    white-space: pre;
  }

  .vscode-url {
    width: 100%;
    border: 1px solid gray;
    background-color: #eeeeee;
    padding: 5px 10px;
    font-family: monospace;
    font-size: 0.85em;
  }

  .click-to-copy {
    cursor: pointer;

    &:hover {
      background-color: rgb(201, 220, 239);

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1.2em;
        vertical-align: middle;
        font-family: outline-icons;
        content: "\f328";
      }
    }
  }

  .click-to-copy,
  .click-to-copy-parent {
    position: relative;

    &::after {
      position: absolute;
      pointer-events: none;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      background-color: #fff;
      opacity: 0;
      transition: opacity 0.5s;
      content: "Copied!";
    }

    &.copied::after {
      opacity: 0.9;
    }
  }


  [class*="good version"] {
    color: #079c2f;
  }

  [class*="bad version"] {
    color: #d60a0a;
  }

  [class*="good value"] {
    color: #079c2f;
  }

  [class*="warning value"] {
    color: #d8aa15;
  }

  [class*="bad value"] {
    color: #d60a0a;
  }

  .ui.inverted.menu #website-menu.ui.search.dropdown>input.search,
  .ui.inverted.menu #website-menu.ui.search.dropdown>input.search:focus,
  .ui.inverted.menu #website-menu.ui.search.active.dropdown>.text:not(.default),
  .ui.inverted.menu #website-menu.ui.search.visible.dropdown>.text:not(.default),
  .ui.inverted.menu #website-menu.ui.search.dropdown>input.search:focus {
    color: rgba(255, 255, 255, 0.7);
    font-family: inherit;
  }

  #HW_badge_cont {
    height: auto;
    width: 20px;
  }

  #HW_badge {
    top: -7px;
    left: 6px;
  }

  .ui.table {
    margin: 1em 0;
  }

  table.summary.table {
    max-width: 550px;
  }

  .ui.list.hierarchy {
    .description {
      padding-bottom: 10px;
    }

    .content>.list {
      padding-top: 2px;
    }
  }

  .ui.list.hierarchy>.item::after,
  .ui.list>.list>.item,
  ol.ui.list>li:first-child::after,
  ul.ui.list>li:first-child::after {
    visibility: visible;
    height: auto;
  }

  .divider:first-child,
  .divider + .divider {
    display: none;
  }

  .filter-count {
    position: absolute;
    right: 2.75em;
    bottom: 0.8em;
    color: #aaa;
    font-size: 0.8em;
  }

  .changed {
    background-color: #fff5d4;
    color: #573a08;
  }

  .ui.form .error.warning {
    input {
      background-color: #fffaf3;
      border-color: #C9BA99;
      color: #573a08;
    }

    label {
      color: #573a08;
    }

    .dropdown {
      .dropdown.icon {
        font-size: 0.85em
      }

      background-color: #fffaf3;
      border-color: #C9BA99;

      .text {
        color: #573a08;
      }

      .item {
        color: #573a08;

        &.active.selected {
          background-color: #fff5d4 !important;
        }

        &.default-value {
          background-color: #fff !important;
        }

        &:hover {
          background-color: #fff5d4 !important;
        }
      }
    }
  }
}

// somehow the progress bar appears outside of the body tag
.turbo-progress-bar {
  height: 2px;
  background-color: #eaeaea;
}

html[data-turbo-preview] {
  // body { opacity: 0.8; }

  .message {
    display: none;
  }

}

.wiki-content {
  min-height: 100px;
  margin-bottom: 50px;
}