/* Swap text and background color for dark mode */
@media (prefers-color-scheme: dark) {
  a {
    color: #9ea3dd;

    &:hover {
      color: #b4baf7;
    }
  }

  body#myapp {
    color: #ddd;
    background-color: #222;

    #website-menu {
      input.prompt {
        color: #ddd;
        background-color: #333;

        &:focus {
          color: #ddd;
          background-color: #111;
        }
      }
    }

    .code-snippet {
      color: #ddd;
      background-color: #111;
    }

    .vscode-url {
      border: 1px solid gray;
      color: #ddd;
      background-color: #111;
    }

    .click-to-copy {
      &:hover {
        background-color: rgb(68, 64, 97);
      }

      &::after {
        background-color: #222;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #ccc;
    }

    img {
      filter: saturate(35%) brightness(85%);
    }

    select {
      color: #ddd;
      background-color: #444;
    }

    .slideout-panel {
      background-color: #222;
    }

    tr.down {
      background-color: #444;
    }

    form .actions {
      background-color: #444;
    }

    footer {
      color: #ccc;
    }

    .ui.checkbox input:checked~.box,
    .ui.checkbox input:checked~label,
    .ui.checkbox label,
    .ui.checkbox+label,
    .ui.toggle.checkbox .box,
    .ui.toggle.checkbox label,
    .ui.toggle.checkbox input:checked~.box,
    .ui.toggle.checkbox input:checked~label,
    .ui.checkbox input:checked:focus~.box,
    .ui.checkbox input:checked:focus~label,
    .ui.checkbox input:not([type="radio"]):indeterminate:focus~.box,
    .ui.checkbox input:not([type="radio"]):indeterminate:focus~label {
      color: #ddd !important;

      &::after {
        color: #ddd !important;
        background-color: #444;
      }
    }

    .ui.toggle.checkbox .box::before,
    .ui.toggle.checkbox label::before {
      background-color: #444;
    }

    .ui.toggle.checkbox:hover .box::before,
    .ui.toggle.checkbox:hover label::before {
      background-color: #333;
    }

    .ui.toggle.checkbox .box::after,
    .ui.toggle.checkbox label::after {
      background-color: #777 !important;
    }

    .ui.message {
      color: #ddd;
      background-color: rgb(51, 51, 51);

      &.info {
        background-color: rgb(60, 70, 83);
      }

      &.warning {
        background-color: rgb(70, 69, 50);
      }

      &.error {
        background-color: rgb(83, 60, 60);
      }
    }

    .ui.loading.form::before {
      background-color: rgba(255, 255, 255, .1);
    }
  }

  .ui.list .list>.item .header,
  .ui.list>.item .header,
  .ui.list .list>.item .description,
  .ui.list>.item .description {
    color: #ddd;
  }

  input::selection,
  textarea::selection {
    color: rgba(0, 0, 0, .87);
    background: #d6daea;
  }

  .ui.button {
    color: #ddd;
    background-color: #555;

    &:hover {
      color: #ddd;
      background-color: #444;
    }
  }

  .ui.negative.button:hover,
  .ui.negative.buttons .button:hover {
    background-color: #841010;
  }

  .ui.basic.button,
  .ui.basic.buttons .button {
    color: rgba(255, 255, 255, .6) !important;
    box-shadow: 0 0 0 1px rgba(225, 225, 225, .5) inset;
  }

  .ui.basic.button:hover,
  .ui.basic.buttons .button:hover {
    color: #ddd !important;
    background-color: rgba(255, 255, 255, .2) !important;
  }

  .ui.label {
    background-color: #444;
  }

  .ui.input,
  .ui.input>input,
  .ui.form textarea,
  .ui.form input:not([type]),
  .ui.form input[type="date"],
  .ui.form input[type="datetime-local"],
  .ui.form input[type="email"],
  .ui.form input[type="file"],
  .ui.form input[type="number"],
  .ui.form input[type="password"],
  .ui.form input[type="search"],
  .ui.form input[type="tel"],
  .ui.form input[type="text"],
  .ui.form input[type="time"],
  .ui.form input[type="url"] {
    color: #ddd;
    background-color: #333;

    &:focus {
      color: #ddd;
      background-color: #111;
    }
  }

  .ui.input.focus>input,
  .ui.input>input:focus {
    color: #ddd;
    background-color: #111;
  }

  .ui.search {
    &>.results {
      background-color: #222;

      .result {
        color: #ddd;

        &.active,
        &:hover {
          color: #222;
        }
      }
    }

    .dropdown>input.search {
      color: #ddd;
      background-color: #333;
    }
  }

  .ui.form .field>label,
  .ui.form .inline.field>label,
  .ui.form .inline.field>p,
  .ui.form .inline.fields .field>label,
  .ui.form .inline.fields .field>p,
  .ui.form .inline.fields>label {
    color: #ddd;
  }

  .ui.inverted.menu {
    background-color: #242d4d;
  }

  .ui.inverted.menu .item,
  .ui.inverted.menu .item>a:not(.ui) {
    color: #ddd;
  }

  .ui.segment {
    background-color: #333;
  }

  .ui.inverted.segment,
  .ui.primary.inverted.segment {
    background-color: #242d4d;
  }

  .ui.dropdown .menu,
  .ui.dropdown .menu>.item,
  .ui.dropdown .menu .selected.item,
  .ui.dropdown.selected,
  .ui.menu .dropdown.item .menu {
    color: #ddd !important;
    background-color: #444;
  }

  .ui.menu .ui.dropdown .menu>.selected.item {
    color: #ddd !important;
  }

  .ui.menu .ui.dropdown .menu>.item {
    color: rgba(255, 255, 255, .87) !important;

    &:hover {
      color: rgba(255, 255, 255, .80) !important;
      background-color: #555 !important;
    }
  }

  .ui.selection.dropdown {
    color: #ddd;
    background-color: #222;
  }

  .ui.selection.dropdown .menu>.item {
    border-top-color: #555;
  }

  .ui.selection.visible.dropdown>.text:not(.default) {
    color: #ddd;
  }

  .ui.statistic>.label,
  .ui.statistics .statistic>.label,
  .ui.table {
    color: #ddd;
    background: transparent;
  }

  .ui.table>thead>tr>th,
  .ui.table.sortable>thead>tr>th {
    color: #ccc;
    background-color: #444;
  }

  .ui.sortable.table:not(.basic)>thead>tr>th:hover {
    color: #ccc;
    background-color: #333;
  }
}