//
// Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
// the trix-editor content (whether displayed or under editing). Feel free to incorporate this
// inclusion directly in any other asset bundle and remove this file.
//
//= require trix/dist/trix

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {

    >action-text-attachment,
    >.attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {

      >action-text-attachment,
      >.attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  [content-type='application/vnd.upspring-hosting-dashboard.contact'],
  [data-trix-content-type='application/vnd.upspring-hosting-dashboard.contact'] {
    display: inline-block;
    padding: 0 5px;
    margin: 0 2px;
    border-radius: 4px;

    border: 1px solid #ddd;
    background-color: #eee;

    @media (prefers-color-scheme: dark) {
      border: 1px solid #222;
      background-color: #111;
    }
  }
}